<template>
  <div class="app-box">
    <div class="investorBox">
      <van-form @submit="onSubmit">
        <van-field
          v-model="formData.province"
          name="省份"
          label="省份"
          placeholder="省份"
          readonly
          right-icon="arrow-down"
          @click="show = true"
        />
        <van-field
          v-model="formData.city"
          name="城市"
          label="城市"
          placeholder="市"
          readonly
          right-icon="arrow-down"
          @click="cityShow = true"
        />
        <van-field
          v-model="formData.area"
          name="区/县"
          label="区/县"
          placeholder="区/县"
          readonly
          right-icon="arrow-down"
          @click="areaShow = true"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >下一步</van-button
          >
        </div>
      </van-form>
    </div>
    <!-- end 省份-->
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
     <!-- end 城市-->
    <van-popup v-model="cityShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="columnScity"
        @confirm="onConfirmScity"
        @cancel="cityShow = false"
      />
    </van-popup>
     <!-- end 区-->
    <van-popup v-model="areaShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="columnArea"
        @confirm="onConfirmArea"
        @cancel="areaShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {getProvince,getCity,getArea} from "@/request/api"
import {getItem,setItem,removeItem} from "@/utils/storage";
import { Toast } from 'vant';
export default {
  data() {
    return {
      formData: {
        province: "",
        provinceId:'',
        city: "",
        cityId:"",
        area: "",
        areaId:""
      },
      columns:[],
      columnScity:[],
      columnArea:[],
      show: false,
      cityShow:false,
      areaShow:false,
    };
  },
  mounted(){
      this.getData();
  },
  methods: {
    getData(){
        getProvince().then(res=>{
            this.columns = res.data;
        })
    },
    onSubmit(values) {
        if(this.formData.province =='' || this.formData.city =='' || this.formData.area ==''){
            Toast.fail('请选择完整地址')
            return
        }
        
        // 验证成功跳转
        setItem('address',this.formData)//保存在缓存
        this.$router.push('/Contract');
    },
    onConfirm(value, index) {
      this.formData.province = value.name;
      this.formData.provinceId = value.code;
      this.formData.city = '';
      this.formData.cityId = '';
      this.formData.area = '';
      this.formData.areaId = '';
      this.show = false;
      //请求城市
      let data = {
          code:value.code
      }
      getCity(data).then(res=>{
          this.columnScity = res.data;
      })
    },
    onConfirmScity(value, index){
      this.formData.city = value.name;
      this.formData.cityId = value.code;
       this.formData.area = '';
      this.formData.areaId = '';
      this.cityShow = false;
      //请求区域
      let data ={
          code:value.code
      }
      getArea(data).then(res=>{
          this.columnArea = res.data;
      })

    },
    onConfirmArea(value, index){
      this.formData.area = value.name;
      this.formData.areaId = value.code;
      this.areaShow = false;
    }
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background:url(../../assets/img/bgera.jpg) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.investorBox {
  width: 90%;
  background: #fff;
  border-radius: 10px;
  margin: auto;
  padding: 0.5rem 0;
  margin-top: 4rem;
}
</style>